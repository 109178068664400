import { Center, Text3D, PresentationControls, Float, Environment, useGLTF, ContactShadows, Html, Sparkles } from '@react-three/drei'
import { Suspense } from 'react';
import { useProgress } from '@react-three/drei'
import { Bars } from 'svg-loaders-react'
import { Light } from 'three';



export default function Experience() {
    const computer = useGLTF('./models/model.gltf');
    function Loader() {
        const { progress } = useProgress()
        return <Html center>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </Html>
    }
    return <>
        <Suspense fallback={<Loader />}>
            <color args={['#241a1a']} attach="background"></color>
            <Text3D
                font={"./fonts/Bangers_Regular.json"}
                size={0.1}
                height={0.05}
                position={[2, 0.85, -0.65]}
                rotation-y={-1.25}
                lineHeight={0.5}

            >
                {'Hello, I am'}
                <meshStandardMaterial color={'#ADD8E6'}></meshStandardMaterial>
            </Text3D>

            <Text3D
                font={"./fonts/Bangers_Regular.json"}
                size={0.2}
                height={0.05}
                position={[2, 0.75, -0.95]}
                rotation-y={-1.25}
                lineHeight={0.5}

            >
                {/* <Sparkles count={50} size={5} speed={0.5} color={'#76B2D5'} position={[1, 0, 1]} castShadow="false"></Sparkles> */}
                {' \nAMANDEEP \n BHULLAR'}
                <meshStandardMaterial color="#1B92D6"></meshStandardMaterial>
            </Text3D>


            <PresentationControls
                global
                rotation={[0.13, 0.1, 0]}
                polar={[-0.4, 0.2]}
                azimuth={[-1, 0.75]}
                config={{ mass: 2, tension: 400 }}>
                <Float rotationIntensity={0.2}>

                    <rectAreaLight
                        width={2.5}
                        height={1.65}
                        intensity={65}
                        color={'#ADD8E6'}
                        rotation={[0.1, Math.PI, 0]}
                        position={[0, 0.55, -1.15]}
                    ></rectAreaLight>

                    <primitive object={computer.scene}
                        position-y={-1.2}>
                        <Html
                            transform
                            wrapperClass='html-screen'
                            distanceFactor={1.72}
                            position={[0, 1.53, -1.4]}
                            rotation-x={-0.256}>
                            <iframe src="https://abhullar.ca"></iframe>
                        </Html>

                    </primitive>




                </Float>
            </PresentationControls>
            <ContactShadows
                position-y={-1.4}
                opacity={0.4}
                scale={5}
                blur={2.4}>

            </ContactShadows>


        </Suspense>

    </>
}